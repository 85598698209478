var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", [
    _c(
      "div",
      { staticClass: "d-flex justify-center flex-column flex-sm-row" },
      [
        _c(
          "div",
          { staticClass: "m-auto mr-sm-4 mr-md-16 custom-page-content" },
          [
            _c("h3", { staticClass: "text-center" }, [
              _vm._v(" How to get SCRL "),
            ]),
            _c(
              "v-list",
              {
                staticStyle: { "border-radius": "24px 24px 0 0" },
                attrs: { subheader: "", color: "#4c356c" },
              },
              [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-avatar",
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-credit-card-outline"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [_vm._v("Bank card")]),
                        _c("v-list-item-subtitle", [
                          _vm._v(" Buy by a bank card "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-avatar",
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-swap-horizontal"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [_vm._v("Exchange")]),
                        _c(
                          "v-list-item-subtitle",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  href: "https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf",
                                  target: "_blank",
                                  "x-small": "",
                                  color: "#5a3e80",
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "mr-1",
                                  attrs: {
                                    height: "14px",
                                    src: "/img/icons/crypto/pancake.ico",
                                  },
                                }),
                                _vm._v(" PancakeSwap "),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
                _c(
                  "v-list-item",
                  [
                    _c("v-list-item-avatar", [
                      _c("div", {
                        staticClass: "bg-menu_icon_marketplace mr-1",
                      }),
                    ]),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [_vm._v("Trade")]),
                        _c(
                          "v-list-item-subtitle",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  to: { name: "Marketplace", params: {} },
                                  "x-small": "",
                                  color: "#5a3e80",
                                },
                              },
                              [_vm._v(" Marketplace ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("v-divider"),
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-avatar",
                      [
                        _c("v-icon", { attrs: { dark: "" } }, [
                          _vm._v("mdi-trophy-outline"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [_vm._v("Win in the game")]),
                        _c("v-list-item-subtitle", [
                          _c("div", { staticClass: "download-section" }, [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://play.google.com/store/apps/details?id=com.FlyingCactus.Wizarre&referrer=utm_source%3Dapp%26utm_medium%3Dfooter",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/img/download-game/google-play-badge.png",
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://cdn.wizarre.io/wizarre-1.6.0.zip",
                                  target: "_blank",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/img/download-game/download-windows.png",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mt-4 tv-container" }, [
              _c("h3", { staticClass: "d-flex tv-container-pair-name" }, [
                _c("img", {
                  staticClass: "ml-2",
                  staticStyle: { width: "40px" },
                  attrs: { src: "/img/scroll_128.png", alt: "SCRL" },
                }),
                _vm._v(" Wizarre SCRL / USD "),
              ]),
              _c("br"),
              _c("iframe", {
                attrs: {
                  id: "dextools-widget",
                  title: "SCRL/USDT",
                  width: "100%",
                  height: "350",
                  src:
                    "https://www.dextools.io/widget-chart/en/bnb/pe-light/0x485766b9ed7c28a45b8e070382bf3308ed0e2d98?theme=dark&chartType=2&drawingToolbars=false&chartResolution=" +
                    _vm.chartPeriod,
                },
              }),
            ]),
          ],
          1
        ),
        _c("div", [
          _c("h3", { staticClass: "text-center" }, [
            _vm._v(" Purchase with bank card "),
          ]),
          _c(
            "div",
            { attrs: { id: "swipelux-container" } },
            [
              _vm.loadingWidget
                ? _c("v-progress-circular", {
                    staticStyle: { "margin-top": "10%", width: "100%" },
                    attrs: { indeterminate: "", size: 120, color: "amber" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }