<template>
    <v-container>
        <div class="d-flex justify-center flex-column flex-sm-row">
            <div class="m-auto mr-sm-4 mr-md-16 custom-page-content">
                <h3 class="text-center">
                    How to get SCRL
                </h3>
                <v-list
                    subheader
                    color="#4c356c"
                    style="border-radius: 24px 24px 0 0"
                >
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon dark>mdi-credit-card-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>Bank card</v-list-item-title>
                            <v-list-item-subtitle>
                                Buy by a bank card
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon dark>mdi-swap-horizontal</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>Exchange</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-btn
                                    href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x52c1751C89fc913ed274d72e8d56DcE4Ee44A5cf"
                                    target="_blank"
                                    x-small
                                    color="#5a3e80"
                                >
                                    <img height="14px" src="/img/icons/crypto/pancake.ico" class="mr-1"/>
                                    PancakeSwap
                                </v-btn>
<!--                                or-->
<!--                                <v-btn href="https://www.bitrue.com/trade/scrl_usdt" target="_blank" x-small-->
<!--                                       color="#5a3e80">-->
<!--                                    <img height="14px" src="/img/icons/crypto/bitrue.ico" class="mr-1"/>-->
<!--                                    Bitrue-->
<!--                                </v-btn>-->
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item>
                        <v-list-item-avatar>
                            <div class="bg-menu_icon_marketplace mr-1"></div>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>Trade</v-list-item-title>
                            <v-list-item-subtitle>
                                <v-btn :to="{ name: 'Marketplace', params: {}}" x-small color="#5a3e80">
                                    Marketplace
                                </v-btn>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <v-list-item>
                        <v-list-item-avatar>
                            <v-icon dark>mdi-trophy-outline</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>Win in the game</v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="download-section">
                                    <a href="https://play.google.com/store/apps/details?id=com.FlyingCactus.Wizarre&referrer=utm_source%3Dapp%26utm_medium%3Dfooter"
                                       target="_blank">
                                        <img src="/img/download-game/google-play-badge.png">
                                    </a>
                                    <a href="https://cdn.wizarre.io/wizarre-1.6.0.zip" target="_blank">
                                        <img src="/img/download-game/download-windows.png">
                                    </a>
                                </div>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <div class="mt-4 tv-container">
                    <h3 class="d-flex tv-container-pair-name">
                        <img style="width:40px;" class="ml-2" src="/img/scroll_128.png" alt="SCRL"/>
                        Wizarre SCRL / USD
                    </h3>
                    <br/>
                    <iframe id="dextools-widget"
                            title="SCRL/USDT"
                            width="100%" height="350"
                            :src="'https://www.dextools.io/widget-chart/en/bnb/pe-light/0x485766b9ed7c28a45b8e070382bf3308ed0e2d98?theme=dark&chartType=2&drawingToolbars=false&chartResolution='+chartPeriod">
                    </iframe>
                </div>
            </div>

            <div>
                <h3 class="text-center">
                    Purchase with bank card
                </h3>
                <div id="swipelux-container">
                    <v-progress-circular
                        v-if="loadingWidget"
                        indeterminate
                        :size="120"
                        color="amber"
                        class=""
                        style="margin-top: 10%;width:100%"
                    ></v-progress-circular>
                </div>
            </div>
        </div>
    </v-container>
</template>

<script>
const config = require('@/config.js');
import PageFiltersSection from "@/components/page-filters-section";

const CHART_PERIOD = '30';
const DEFAULT_BUY_AMOUNT_USD = 30;

export default {
    components: {PageFiltersSection},
    data() {
        return {
            chartPeriod: CHART_PERIOD,
            loadingWidget: true,
        };
    },
    mounted: function () {
        this.init();
    },
    destroyed() {
    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        init() {
            this.$loadScript(config.swipelux_api_url)
                .then(() => {
                    const swipeluxContainer = document.getElementById("swipelux-container");
                    const settings = {
                        apiKey: config.swipelux_api_key,
                        colors: {
                            main: "#fff",
                            background: "#5a3e80"
                        },
                        availableReceiveCurrency: config.swipelux_receive_currency,
                        defaultValues: {
                            fiatAmount: DEFAULT_BUY_AMOUNT_USD,
                            targetAddress: {
                                value: this.web3.address || null,
                                editable: true,
                            },
                        },
                    };

                    const widget = new window.SwipeluxWidget(swipeluxContainer, settings);
                    widget.init();
                    this.loadingWidget = false;
                })
                .catch(() => {
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Cannot load Swipelux widget. Please contact Wizarre team.'
                    });
                    throw new Error('CANNOT_LOAD_SWIPELUX_SDK');
                });
        },
    }
}
</script>

<style lang="scss" scoped>
.custom-page-content {
    width: 100%;
    max-width: 500px;
}

#dextools-widget {
    border: none;
    border-radius: 0 0 24px 24px;
    margin-bottom: -15px;
}

.bg-menu_icon_marketplace {
    width: 15px;
    height: 21px;
    background: url('/img/icons/common-icons.png') -45px -10px;
}

.tv-container {
    background: #4c356c;
    border-radius: 0 0 24px 24px;
    padding-top: 5px;
    padding-bottom: 7px;
}

.tv-container-pair-name {
    margin-bottom: -12px
}

#swipelux-container {
    min-height: 600px;
}

.download-section {
    .ios span {
        line-height: 5px;
    }

    a {
        margin-right: 10px;

        img {
            height: 40px;

            &.disabled {
                opacity: 0.5;
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .download-section {
        a {
            margin-right: 3px;

            img {
                height: 32px;
            }
        }
    }
}
</style>
